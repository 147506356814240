@use '../../App';
$light-gray: #efefef;


.title-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 8rem;
}

.projects-grid{
    display: grid;
    margin-top: 3rem;
    grid-template-columns: repeat(3, 33.33%);
}

.project-grid-item{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 50em) {
    .title-text{
        font-size: 2.2rem;
    }
    .projects-grid{
        grid-template-columns: 100%;
    }
}