@use '../../App';
$light-gray: #efefef;
$darkest-gray: #1c1c1c;
$medium-gray: #9e9e9e;
$stage-four: #fec86e;

.title-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 8rem;
}

.contact-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-content{
    margin-top: 2rem;
    width: 50%;
    font-size: 1.15rem;
    text-align: center;

    a{
        text-decoration: none;
        color: $light-gray;
    }
}

.contact-form{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // background-color: $darkest-gray;

    .form-group{
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        label{
            color: $light-gray;
        }
        input, textarea{
            // margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            width: 35vw;
            padding: 1rem;
            border: none;
            background-color: transparent;
            box-shadow: inset 0rem 0rem 0rem 0.15rem $light-gray;
            font-family: 'Nunito Sans', sans-serif !important;
            font-size: 1rem;
            color: $light-gray;
            ::placeholder{
                color: $medium-gray;
            }

            caret-color: $light-gray;
        }

        input:focus, textarea:focus{
            border: none;
            box-shadow: none;
        }

        button{
            padding: 1.2rem 3rem;
            border-radius: 0.5rem;
            font-size: 1rem;
            cursor: pointer;
            font-weight: bold;
        }
    }

    }

@media (max-width: 50em) {
    .contact-content{
        margin-top: 2rem;
        width: 90%;
        font-size: 1.15rem;
        text-align: center;
    }

    .contact-form{
        .form-group{
            input, textarea{
                width: 75vw;
            }
        }
    }
}