.footer-container {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #9e9e9e;
}

.footer-container .footer-text {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.1rem;
}

.footer-container .footer-text .footer-right {
  font-size: 1.5rem;
}

.footer-container .footer-text .footer-right a {
  color: inherit;
}

.footer-container .footer-text .footer-right a svg {
  margin: 0 0.5rem;
}

@media (max-width: 50em) {
  .footer-container .footer-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer-container .footer-text .footer-right {
    margin-top: 1.5rem;
  }
}
