@use '../../App';
.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  margin-top: 7rem;
}

.hero .hero-text {
  font-size: 4.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 4rem;
}

.hero .hero-text .hero-text-main {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.hero .hero-yellow-text {
  padding: 0.3rem 1rem;
  margin: 0 1rem;
}

.hero .hero-content {
  margin-top: 3rem;
  width: 50%;
  font-size: 1.3rem;
  text-align: center;
}

.hero .hero-cta {
  margin-top: 2.5rem;
  margin-bottom: 5rem;
}

.hero .hero-cta button {
  padding: 1.2rem 3rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 60em) {
  .hero {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 3rem;
  }
  .hero .hero-text {
    font-size: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 1rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .hero .hero-text .hero-text-main {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .hero .hero-yellow-text {
    padding: 0.3rem 1rem;
    margin: 0 1rem;
  }
  .hero .hero-content {
    margin-top: 3rem;
    width: 87%;
    font-size: 1rem;
    text-align: center;
  }
  .hero .hero-cta {
    margin-top: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hero .hero-cta button {
    padding: 1.2rem 3rem;
    margin: 0.5rem;
    border-radius: 1rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
  }
}
