$stage-four: #fec86e;

.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header-image{
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 2rem 0rem;
    }

    .title-desc{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    text-align: center;
    width: 60%;
    font-size: 1.3rem;
    
}
}

.header-image{
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 2rem 0rem;
    }

.background-container{

    .background-grid{
        display: grid;
        margin-top: 3rem;
        align-items: flex-start;
        grid-template-columns: repeat(2, 50%);
    }

    .background-left{
        display: flex;
        justify-content: center;
        margin: 0 2rem;
        flex-direction: column;

        .background-about{
            font-size: 1.3rem;
        }

        .about{
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .background-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 2rem;
        
        .role, .timeline{
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .background-my-role{
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }

        .background-timeline{
            font-size: 1.3rem;
        }
    }
}

.user-problems-container, .user-testing-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .user-problems-grid, .user-testing-grid{
        display: grid;
        grid-template-columns: repeat(3, 33.33%);
        margin-top: 3rem;
        
        .user-problem, .user-testing{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem 1rem;
            margin: 0 1rem;

            .user-problem-text, .user-testing-text{
                font-size: 1.3rem;
            }
        }
    }
}

.solving-problem, .high-fidelity, .takeaway{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-content{
    margin-top: 1.5rem;
    text-align: center;
    width: 70%;
    font-size: 1.3rem;
}

.title-text-case{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 1rem;
    text-align: center;
}

.breadcrumbs{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    font-size: 1.3rem;
    a{
        text-decoration: none;
        color: inherit;
    }
}

.back-to-home{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 5rem;
    a{
        color: inherit;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        .back-home{
            background-color: $stage-four;
            border: none;
            width: 20%;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            font-size: 1.2rem;
        }
    }
}

.impact-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .impact-grid{
        display: grid;
        grid-template-columns: repeat(3, 33.33%);
        margin-top: 2rem;

        .impact{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem 1rem;
            margin: 0 1rem;
            flex-direction: row;

            .impact-number{
                font-weight: bold;
                font-size: 2.5rem;
                margin: 0 1rem;
            }

            .impact-text{
                font-size: 1.3rem;
            }
        }
    }
}


@media (max-width: 60em) {

.header{
    .title-desc{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        margin-top: 1rem;
        font-size: 1.1rem;
    }
}

.user-problems-container, .user-testing-container{
    .user-problems-grid, .user-testing-grid{
        display: grid;
        grid-template-columns: repeat(1, 100%);

        .user-problem, .user-testing{
            margin-top: 2rem;
            .user-problem-text, .user-testing-text{
                font-size: 1.2rem;
            }
        }
    }

}

.background-container{

    .background-grid{
        display: grid;
        grid-template-columns: repeat(1, 100%);
        margin-top: 1rem;
    }

    .background-left, .background-right{
        margin-top: 2rem;
        .background-my-role{
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        .background-timeline{
            font-size: 1.1rem;
        }

        .background-about{
            font-size: 1.1rem;
        }
    }
}

.impact-container{
    .impact-grid{
        grid-template-columns: repeat(1, 100%) !important;

        .impact{
            margin-top: 2rem;
            .impact-text{
                font-size: 1.2rem;
            }
        }
    }
}

.top-content{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 1.1rem;
}

.back-to-home{
    a{
        text-decoration: none;
        color: inherit;
        .back-home{
            width: 70%;
            font-size: 1.2rem;
        }
    }
}

.breadcrumbs{
    font-size: 1.2rem;
}
}