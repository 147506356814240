@use '../../App';
.title-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.7rem;
  margin-top: 8rem;
}

.projects-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 3rem;
  -ms-grid-columns: (33.33%)[3];
      grid-template-columns: repeat(3, 33.33%);
}

.project-grid-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 50em) {
  .title-text {
    font-size: 2.2rem;
  }
  .projects-grid {
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
  }
}
