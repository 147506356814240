@use '../../App';
$golden-yellow: #fec86e;
$light-gray: #efefef;
$medium-gray: #9e9e9e;
$darkest-gray: #1c1c1c;
$darker-gray: rgb(45, 45, 45);
$darker-gray-navbar: rgba(45,45,45,0.8);


.new-navbar-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .new-navbar{
        display: grid;
        width: 50%;
        align-items: center;
        grid-template-columns: repeat(3, 33.33%);
        // justify-content: space-evenly;

        .new-navbar-text, .brand-logo{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $medium-gray;
            font-size: 1.2rem;
            text-decoration: none;
        }
        
        .new-navbar-text:hover{
            color: $light-gray;
        }
    }
}


@media (max-width: 55em) {
    
    .new-navbar-container{
        .new-navbar{
            width: 80%;
        }
    }

}