.title-text-about{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 2rem;
    margin-bottom: 0rem;
    text-align: center;
}


.about-me-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin-top: 4rem;

    .about-me-grid{
        display: grid;
        grid-template-columns: 60% 40%;

        .about-me-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            width: 70%;
            margin: 0 auto;
            font-size: 1.3rem;

            a{
                text-decoration: none;
            }
        }

        .about-me-image{
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }
    }
}

.skill-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .skill-grid{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4,25%);
        margin-top: 2rem;

        .skill-section{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.3rem;

            .skill-top-image{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .skill-title{
                margin-top: -3rem;
                text-align: center;
                font-size: 1.5rem;
            }

            .skills{
                width: 90%;
                margin-top: 1rem;
                font-size: 1.1rem;
                display: flex;
                text-align: center;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 60em) {
    .about-me-container{  

        .about-me-grid{
            grid-template-columns: 100%;

            .about-me-content{
                width: 80%;
            }
        }

    }

    .skill-container{
    
        .skill-grid{
            grid-template-columns: 100%;

            .skill-section{
                margin-top: 3rem;
            }
        }
    }
}