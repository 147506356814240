@use '../../App';
.title-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.7rem;
  margin-top: 8rem;
}

.contact-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact-content {
  margin-top: 2rem;
  width: 50%;
  font-size: 1.15rem;
  text-align: center;
}

.contact-content a {
  text-decoration: none;
  color: #efefef;
}

.contact-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 2rem;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.contact-form .form-group {
  width: 100%;
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contact-form .form-group label {
  color: #efefef;
}

.contact-form .form-group input, .contact-form .form-group textarea {
  margin-bottom: 1.5rem;
  width: 35vw;
  padding: 1rem;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: inset 0rem 0rem 0rem 0.15rem #efefef;
          box-shadow: inset 0rem 0rem 0rem 0.15rem #efefef;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 1rem;
  color: #efefef;
  caret-color: #efefef;
}

.contact-form .form-group input ::-webkit-input-placeholder, .contact-form .form-group textarea ::-webkit-input-placeholder {
  color: #9e9e9e;
}

.contact-form .form-group input :-ms-input-placeholder, .contact-form .form-group textarea :-ms-input-placeholder {
  color: #9e9e9e;
}

.contact-form .form-group input ::-ms-input-placeholder, .contact-form .form-group textarea ::-ms-input-placeholder {
  color: #9e9e9e;
}

.contact-form .form-group input ::placeholder, .contact-form .form-group textarea ::placeholder {
  color: #9e9e9e;
}

.contact-form .form-group input:focus, .contact-form .form-group textarea:focus {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.contact-form .form-group button {
  padding: 1.2rem 3rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 50em) {
  .contact-content {
    margin-top: 2rem;
    width: 90%;
    font-size: 1.15rem;
    text-align: center;
  }
  .contact-form .form-group input, .contact-form .form-group textarea {
    width: 75vw;
  }
}
