@use '../../App';
.new-navbar-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2rem;
}

.new-navbar-container .new-navbar {
  display: -ms-grid;
  display: grid;
  width: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: (33.33%)[3];
      grid-template-columns: repeat(3, 33.33%);
}

.new-navbar-container .new-navbar .new-navbar-text, .new-navbar-container .new-navbar .brand-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #9e9e9e;
  font-size: 1.2rem;
  text-decoration: none;
}

.new-navbar-container .new-navbar .new-navbar-text:hover {
  color: #efefef;
}

@media (max-width: 55em) {
  .new-navbar-container .new-navbar {
    width: 80%;
  }
}
