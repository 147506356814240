.title-text-about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.7rem;
  margin-top: 2rem;
  margin-bottom: 0rem;
  text-align: center;
}

.about-me-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 4rem;
}

.about-me-container .about-me-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 60% 40%;
      grid-template-columns: 60% 40%;
}

.about-me-container .about-me-grid .about-me-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70%;
  margin: 0 auto;
  font-size: 1.3rem;
}

.about-me-container .about-me-grid .about-me-content a {
  text-decoration: none;
}

.about-me-container .about-me-grid .about-me-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
}

.skill-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.skill-container .skill-grid {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (25%)[4];
      grid-template-columns: repeat(4, 25%);
  margin-top: 2rem;
}

.skill-container .skill-grid .skill-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.3rem;
}

.skill-container .skill-grid .skill-section .skill-top-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.skill-container .skill-grid .skill-section .skill-title {
  margin-top: -3rem;
  text-align: center;
  font-size: 1.5rem;
}

.skill-container .skill-grid .skill-section .skills {
  width: 90%;
  margin-top: 1rem;
  font-size: 1.1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 60em) {
  .about-me-container .about-me-grid {
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
  }
  .about-me-container .about-me-grid .about-me-content {
    width: 80%;
  }
  .skill-container .skill-grid {
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
  }
  .skill-container .skill-grid .skill-section {
    margin-top: 3rem;
  }
}
