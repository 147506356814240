@use '../../App';

$golden-yellow: #fec86e;

.hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 7rem;

    .hero-text{
        font-size: 4.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;
        .hero-text-main{
            flex: 1;
        }
    }

    .hero-yellow-text{
        padding: 0.3rem 1rem;
        margin: 0 1rem;
    }

    .hero-content{
        margin-top: 3rem;
        width: 50%;
        font-size: 1.3rem;
        text-align: center;
    }

    .hero-cta{
        margin-top: 2.5rem;
        margin-bottom: 5rem;
        button{
            padding: 1.2rem 3rem;
            margin: 0 1rem;
            border-radius: 0.5rem;
            font-size: 1.2rem;
            cursor: pointer;
            font-weight: bold;
        }
    }
}

@media (max-width: 60em) {
    .hero{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        // background-color: #1c1c1c;
       
    .hero-text{
        font-size: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        justify-content: center;

        .hero-text-main{
            flex: 1;
        }
        // display: none;
    }

    .hero-yellow-text{
        // display: none;
        padding: 0.3rem 1rem;
        margin: 0 1rem;
    }

    .hero-content{
        // display: none;
        margin-top: 3rem;
        width: 87%;
        font-size: 1rem;
        text-align: center;
    }

    .hero-cta{
        // display: none;
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        button{
            padding: 1.2rem 3rem;
            margin: 0.5rem;
            border-radius: 1rem;
            font-size: 1rem;
            cursor: pointer;
            font-weight: bold;
        }
    }
    }
}