@use '../../App';
$light-gray: #efefef;
$darkest-gray: #1c1c1c;

.title-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 8rem;
    text-align: center;
}

.ux-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 3rem;

    a{
        text-decoration: none;
        color: inherit;
        .ux-project{
            display: grid;
            margin-bottom: 2rem;
            grid-template-columns: repeat(2, 50%);
            padding: 2rem;
    
            .ux-project-details{
                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: center;
                padding: 0 3rem;
                // order: 0;
    
                .ux-project-title{
                    font-size: 2.3rem;
                    margin-bottom: 1rem;
                }
    
                .ux-project-desc{
                    font-size: 1.2rem;
                }
    
                .ux-read-more{
                    margin-top: 2rem;
                    padding: 1rem;
                    border: 2px solid $darkest-gray;
                    font-weight: bold;
                    width: 8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
    
        .ux-project-image{
            display: flex;
            justify-content: center;
            align-items: center;
            // order: 1;
        }
    
        }
    }
}

@media (max-width: 50em) {
    .title-text{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .ux-container{
        a{
        .ux-project{
            grid-template-columns: repeat(1, 100%);

            .ux-project-details{
            order: 1 !important;
            margin: 2rem 0rem;
            }

            .ux-project-image{
                order: 0 !important;
                margin: 2rem 0rem;
            }
        }
    }
}

}