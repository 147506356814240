@use '../../App';
.title-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.7rem;
  margin-top: 8rem;
  text-align: center;
}

.ux-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 3rem;
}

.ux-container a {
  text-decoration: none;
  color: inherit;
}

.ux-container a .ux-project {
  display: -ms-grid;
  display: grid;
  margin-bottom: 2rem;
  -ms-grid-columns: (50%)[2];
      grid-template-columns: repeat(2, 50%);
  padding: 2rem;
}

.ux-container a .ux-project .ux-project-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 3rem;
}

.ux-container a .ux-project .ux-project-details .ux-project-title {
  font-size: 2.3rem;
  margin-bottom: 1rem;
}

.ux-container a .ux-project .ux-project-details .ux-project-desc {
  font-size: 1.2rem;
}

.ux-container a .ux-project .ux-project-details .ux-read-more {
  margin-top: 2rem;
  padding: 1rem;
  border: 2px solid #1c1c1c;
  font-weight: bold;
  width: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ux-container a .ux-project .ux-project-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 50em) {
  .title-text {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ux-container a .ux-project {
    -ms-grid-columns: (100%)[1];
        grid-template-columns: repeat(1, 100%);
  }
  .ux-container a .ux-project .ux-project-details {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
    margin: 2rem 0rem;
  }
  .ux-container a .ux-project .ux-project-image {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
    margin: 2rem 0rem;
  }
}
