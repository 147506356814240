@font-face {
  font-family: 'Clash Display Bold';
  src: url("../fonts/ClashDisplay-Bold.otf");
}

body {
  background: #2d2d2d;
  margin: 0;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#root {
  width: 100%;
  max-width: 1920px;
  padding: 0 2rem;
}

.gotham {
  font-family: 'Gotham Book', sans-serif;
}

.nunito-sans {
  font-family: 'Nunito Sans', sans-serif;
  line-height: 1.2em !important;
}

.monea {
  font-family: 'Monea Alegante', sans-serif;
}

.clash {
  font-family: 'Clash Display Bold', sans-serif;
}

.text-light-gray {
  color: #efefef;
}

.text-medium-gray {
  color: #9e9e9e;
}

.text-dark-gray {
  color: #383838;
}

.text-darker-gray {
  color: #2d2d2d;
}

.text-golden-yellow {
  color: #fec86e;
}

.text-stage-three {
  color: #fed58f;
}

.text-stage-four {
  color: #fec86e;
}

.text-stage-two {
  color: #fdeacb;
}

.text-stage-one {
  color: #fffbf6;
}

.dark-gray {
  background-color: #383838;
}

.darker-gray {
  background-color: #2d2d2d;
}

.stage-three {
  background-color: #fed58f;
}

.stage-two {
  background-color: #fdeacb;
}

.stage-four {
  background-color: #fec86e;
}

.stage-one {
  background-color: #fffbf6;
}

.golden-yellow {
  background-color: #fec86e;
}

.no-border {
  border: none;
}

.golden-yellow-border {
  border: 1px solid #fec86e;
}

.stage-three-border {
  border: 1px solid #fed58f;
}

.stage-one-border {
  border: 1px solid #fffbf6;
}

.stage-four-border {
  border: 1px solid #fec86e;
}

.stage-two-border {
  border: 1px solid #fdeacb;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 40em) {
  #root {
    width: 100%;
    padding: 0 !important;
  }
}
