$medium-gray: #9e9e9e;

.footer-container{
    width: 100%;
    margin-top: 8rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $medium-gray;

    .footer-text{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.1rem;
        .footer-right{
            font-size: 1.5rem;
            a{
                color: inherit;
                svg{
                    margin: 0 0.5rem;
                }
            }
        }
    }
}

@media (max-width: 50em) {
    .footer-container{
        .footer-text{
            flex-direction: column;

            .footer-right{
                margin-top: 1.5rem;
            }
        }
    }
}