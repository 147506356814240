.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header .header-image {
  width: 100%;
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 2rem 0rem;
}

.header .title-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1.5rem;
  text-align: center;
  width: 60%;
  font-size: 1.3rem;
}

.header-image {
  width: 100%;
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 2rem 0rem;
}

.background-container .background-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 3rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-grid-columns: (50%)[2];
      grid-template-columns: repeat(2, 50%);
}

.background-container .background-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.background-container .background-left .background-about {
  font-size: 1.3rem;
}

.background-container .background-left .about {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.background-container .background-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 2rem;
}

.background-container .background-right .role, .background-container .background-right .timeline {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.background-container .background-right .background-my-role {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.background-container .background-right .background-timeline {
  font-size: 1.3rem;
}

.user-problems-container, .user-testing-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.user-problems-container .user-problems-grid, .user-problems-container .user-testing-grid, .user-testing-container .user-problems-grid, .user-testing-container .user-testing-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (33.33%)[3];
      grid-template-columns: repeat(3, 33.33%);
  margin-top: 3rem;
}

.user-problems-container .user-problems-grid .user-problem, .user-problems-container .user-problems-grid .user-testing, .user-problems-container .user-testing-grid .user-problem, .user-problems-container .user-testing-grid .user-testing, .user-testing-container .user-problems-grid .user-problem, .user-testing-container .user-problems-grid .user-testing, .user-testing-container .user-testing-grid .user-problem, .user-testing-container .user-testing-grid .user-testing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3rem 1rem;
  margin: 0 1rem;
}

.user-problems-container .user-problems-grid .user-problem .user-problem-text, .user-problems-container .user-problems-grid .user-problem .user-testing-text, .user-problems-container .user-problems-grid .user-testing .user-problem-text, .user-problems-container .user-problems-grid .user-testing .user-testing-text, .user-problems-container .user-testing-grid .user-problem .user-problem-text, .user-problems-container .user-testing-grid .user-problem .user-testing-text, .user-problems-container .user-testing-grid .user-testing .user-problem-text, .user-problems-container .user-testing-grid .user-testing .user-testing-text, .user-testing-container .user-problems-grid .user-problem .user-problem-text, .user-testing-container .user-problems-grid .user-problem .user-testing-text, .user-testing-container .user-problems-grid .user-testing .user-problem-text, .user-testing-container .user-problems-grid .user-testing .user-testing-text, .user-testing-container .user-testing-grid .user-problem .user-problem-text, .user-testing-container .user-testing-grid .user-problem .user-testing-text, .user-testing-container .user-testing-grid .user-testing .user-problem-text, .user-testing-container .user-testing-grid .user-testing .user-testing-text {
  font-size: 1.3rem;
}

.solving-problem, .high-fidelity, .takeaway {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.top-content {
  margin-top: 1.5rem;
  text-align: center;
  width: 70%;
  font-size: 1.3rem;
}

.title-text-case {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.7rem;
  margin-top: 1rem;
  text-align: center;
}

.breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 4rem;
  font-size: 1.3rem;
}

.breadcrumbs a {
  text-decoration: none;
  color: inherit;
}

.back-to-home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.back-to-home a {
  color: inherit;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
}

.back-to-home a .back-home {
  background-color: #fec86e;
  border: none;
  width: 20%;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
}

.impact-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.impact-container .impact-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (33.33%)[3];
      grid-template-columns: repeat(3, 33.33%);
  margin-top: 2rem;
}

.impact-container .impact-grid .impact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3rem 1rem;
  margin: 0 1rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.impact-container .impact-grid .impact .impact-number {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0 1rem;
}

.impact-container .impact-grid .impact .impact-text {
  font-size: 1.3rem;
}

@media (max-width: 60em) {
  .header .title-desc {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  .user-problems-container .user-problems-grid, .user-problems-container .user-testing-grid, .user-testing-container .user-problems-grid, .user-testing-container .user-testing-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (100%)[1];
        grid-template-columns: repeat(1, 100%);
  }
  .user-problems-container .user-problems-grid .user-problem, .user-problems-container .user-problems-grid .user-testing, .user-problems-container .user-testing-grid .user-problem, .user-problems-container .user-testing-grid .user-testing, .user-testing-container .user-problems-grid .user-problem, .user-testing-container .user-problems-grid .user-testing, .user-testing-container .user-testing-grid .user-problem, .user-testing-container .user-testing-grid .user-testing {
    margin-top: 2rem;
  }
  .user-problems-container .user-problems-grid .user-problem .user-problem-text, .user-problems-container .user-problems-grid .user-problem .user-testing-text, .user-problems-container .user-problems-grid .user-testing .user-problem-text, .user-problems-container .user-problems-grid .user-testing .user-testing-text, .user-problems-container .user-testing-grid .user-problem .user-problem-text, .user-problems-container .user-testing-grid .user-problem .user-testing-text, .user-problems-container .user-testing-grid .user-testing .user-problem-text, .user-problems-container .user-testing-grid .user-testing .user-testing-text, .user-testing-container .user-problems-grid .user-problem .user-problem-text, .user-testing-container .user-problems-grid .user-problem .user-testing-text, .user-testing-container .user-problems-grid .user-testing .user-problem-text, .user-testing-container .user-problems-grid .user-testing .user-testing-text, .user-testing-container .user-testing-grid .user-problem .user-problem-text, .user-testing-container .user-testing-grid .user-problem .user-testing-text, .user-testing-container .user-testing-grid .user-testing .user-problem-text, .user-testing-container .user-testing-grid .user-testing .user-testing-text {
    font-size: 1.2rem;
  }
  .background-container .background-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (100%)[1];
        grid-template-columns: repeat(1, 100%);
    margin-top: 1rem;
  }
  .background-container .background-left, .background-container .background-right {
    margin-top: 2rem;
  }
  .background-container .background-left .background-my-role, .background-container .background-right .background-my-role {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  .background-container .background-left .background-timeline, .background-container .background-right .background-timeline {
    font-size: 1.1rem;
  }
  .background-container .background-left .background-about, .background-container .background-right .background-about {
    font-size: 1.1rem;
  }
  .impact-container .impact-grid {
    -ms-grid-columns: (100%)[1] !important;
        grid-template-columns: repeat(1, 100%) !important;
  }
  .impact-container .impact-grid .impact {
    margin-top: 2rem;
  }
  .impact-container .impact-grid .impact .impact-text {
    font-size: 1.2rem;
  }
  .top-content {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 1.1rem;
  }
  .back-to-home a {
    text-decoration: none;
    color: inherit;
  }
  .back-to-home a .back-home {
    width: 70%;
    font-size: 1.2rem;
  }
  .breadcrumbs {
    font-size: 1.2rem;
  }
}
