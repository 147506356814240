$dark-gray: #383838;
$light-gray: #efefef;
$golden-yellow: #fec86e;
$medium-gray: #9e9e9e;
$darker-gray: #2d2d2d;
$darkest-gray: #1c1c1c;


// Pastel shades
// $stage-one: #98DDCA;
// $green: #D5ECC2;
// $yellow: #FFD3B4;
// $red: #FFAAA7;

$stage-one: #fffbf6;
$stage-two: #fdeacb;
$stage-three: #fed58f;
$stage-four: #fec86e;

@font-face {
    font-family: 'Clash Display Bold';
    src: url('../fonts/ClashDisplay-Bold.otf');
}


body{
    background: $darker-gray;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#root{
    width: 100%;
    max-width: 1920px;
    padding: 0 2rem;
}

.gotham{
    font-family: 'Gotham Book', sans-serif;
}

.nunito-sans{
    font-family: 'Nunito Sans', sans-serif;
    line-height: 1.2em !important;
}

.monea{
    font-family: 'Monea Alegante', sans-serif;
}

.clash{
    font-family: 'Clash Display Bold', sans-serif;
}


.text-light-gray{
    color: $light-gray;
}

.text-medium-gray{
    color: $medium-gray;
}

.text-dark-gray{
    color: $dark-gray;
}

.text-darker-gray{
    color: $darker-gray;
}

.text-golden-yellow{
    color: $golden-yellow;
}

.text-stage-three{
    color: $stage-three;
}

.text-stage-four{
    color: $stage-four;
}

.text-stage-two{
    color: $stage-two;
}

.text-stage-one{
    color: $stage-one;
}

.dark-gray{
    background-color: $dark-gray;
}

.darker-gray{
    background-color: $darker-gray;
}

.stage-three{
    background-color: $stage-three;
}

.stage-two{
    background-color: $stage-two;
}

.stage-four{
    background-color: $stage-four;
}

.stage-one{
    background-color: $stage-one;
}

.golden-yellow{
    background-color: $golden-yellow;
}

.no-border{
    border: none;
}

.golden-yellow-border{
    border: 1px solid $golden-yellow;
}

.stage-three-border{
    border: 1px solid $stage-three;
}

.stage-one-border{
    border: 1px solid $stage-one;
}

.stage-four-border{
    border: 1px solid $stage-four;
}

.stage-two-border{
    border: 1px solid $stage-two;
}

.cursor-pointer{
    cursor: pointer;
}

@media (max-width: 40em) {
    #root{
        width: 100%;
        // padding: 0 2rem;
        padding: 0 !important;
    }
}